import React from "react";
import { withLocalize, Translate } from "react-localize-redux";

export class Unsubscribe extends React.Component {
  render() {
    return (
      <div className="Welcome Unsubscribe">
        <div className="Banner">
          <div className="Container">
            <div className="Table">
              <div className="Cell Cell_01">
                <h1 className="Title">
                  <Translate id="Welcome" />
                </h1>
                <h2 className="Subtitle">
                  <Translate id="Welcome_01" />
                </h2>
              </div>
              <div className="Cell Cell_02" />
            </div>
          </div>
        </div>

        <div className="Container HeightPadding">
          <div className="StructureTable">
            <div className="RightCell">
              <div className="WhiteBox">
                <p>
                  <Translate id="Unsubscribe" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withLocalize(Unsubscribe);
