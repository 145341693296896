import "babel-polyfill"; //IE11 support
import React from "react";
import axios from "axios";
import { withLocalize } from "react-localize-redux";
import { Transition } from "react-transition-group";
import { renderToStaticMarkup } from "react-dom/server"; //for react-localize-redux

import Header from "./components/BaseUI/header";
import CookiesBar from "./components/BaseUI/cookies";
import Footer from "./components/BaseUI/footer";
import MoreInfo from "./components/Popups/MoreInfo";
import Instructions from "./components/Popups/Instructions";

import Router from "./components/Helpers/router";

console.log(process.env.NODE_ENV);

export class App extends React.Component {
  displayName = App.name;
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      collectedData: [],
      fixedHeader: false,
      instructions: false,
      instructionsPopup: true,
      cookiePolicy: true,
      requireNewPosition: false,
      popupBase: null,
      selectedLanguage: "en-GB",
      accessibility: false,
      popupStatus: {
        isVisible: false,
        data: null,
        name: ""
      }
    };

    //translation functionality
    this.props.initialize({
      languages: [
        { name: "English", code: "en-GB" },
        { name: "French", code: "fr-FR" },
        { name: "French (Canada)", code: "fr-CA" },
        { name: "Spanish (LATAM)", code: "es-ES" },
        { name: "Chinese", code: "zh-CN" }
      ],
      translation: {}, //globalTranslations, <- loaded below separately by language
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        onMissingTranslation: () => {
          return "";
        } //comment out to see missing translations
      }
    });

    this.loadTranslation(this.state.selectedLanguage);

    // LOCAL STORAGE

    if (sessionStorage.getItem("CPS") === "false") {
      this.state.cookiePolicy = false; //not setState because in constructor
    }

    if (sessionStorage.getItem("IPP") === "false") {
      this.state.instructionsPopup = false; //not setState because in constructor
    }
  }

  loadTranslation = languageCode => {
    axios
      .get("/translations/" + languageCode + ".json", {}, {})
      .then(response => {
        if (response.status === 200) {
          this.props.setActiveLanguage(languageCode);
          this.props.addTranslationForLanguage(response.data, languageCode);
        }
      });
  };

  changeLanguage = selectedLanguage => {
    this.setState({ selectedLanguage });
    this.loadTranslation(selectedLanguage);
  };

  selectedItems = items => {
    this.setState({ selectedItems: items });
  };

  fixedHeader = response => {
    this.setState({ fixedHeader: response });
  };

  collectedData = response => {
    this.setState({ collectedData: response, isRegistered: true });
  };

  openPopup = (Name, Data) => {
    this.setState({
      popupStatus: {
        isVisible: true,
        data: Data,
        name: Name
      }
    });
  };

  closePopup = () => {
    this.setState({
      popupStatus: {
        ...this.state.popupStatus,
        isVisible: false,
        name: ""
      }
    });

    // Timeout waits for animation to end
    setTimeout(() => {
      document.body.classList.remove("popupOpen");
    }, 200);
  };

  fixedHeader = boolean => {
    this.setState({ fixedHeader: boolean });
  };

  instructions = boolean => {
    this.setState({ instructions: boolean });
  };

  cookiePolicy = boolean => {
    this.setState({ cookiePolicy: boolean });
  };

  instructionsPopupState = boolean => {
    this.setState({ instructionsPopup: boolean });
  };

  popupBase = popupBase => {
    this.setState({ popupBase });
  };

  requireNewPosition = () => {
    this.setState({ requireNewPosition: true }, function() {
      this.setState({ requireNewPosition: false });
    });
  };

  toggleAccessibility = (accessVal) => {
    this.setState({
      accessibility: accessVal
    }, function () {
      sessionStorage.setItem("Accessibility", this.state.accessibility);
    });
  }

  render() {
    return (
      <div
        className={
          "App" +
          (this.state.accessibility ? " Accessibility" : "") +
          (this.state.cookiePolicy ? " CookieBar" : "") +
          (this.state.fixedHeader ? " HeaderFixed" : "")
        }
      >
        {this.state.cookiePolicy ? (
          <CookiesBar cookiePolicy={this.cookiePolicy} />
        ) : null}

        <div className="Main">
          <div className="Content">
            <Header
              languages={this.props.languages}
              selected={this.state.selectedLanguage}
              onSubmit={this.changeLanguage}
              openPopup={this.openPopup}
              instructions={this.state.instructions}
              instructionsPopup={this.state.instructionsPopup}
              instructionsPopupState={this.instructionsPopupState}
              currentPopup={this.state.popupStatus.name}
              popupBase={this.popupBase}
              requireNewPosition={this.state.requireNewPosition}
            />
            <Router
              openPopup={this.openPopup}
              fixedHeader={this.fixedHeader}
              instructions={this.instructions}
              collectedData={this.collectedData}
              isRegistered={this.state.isRegistered}
              selectItems={this.selectedItems}
              selectedItems={this.state.selectedItems}
              language={this.state.selectedLanguage}
            />
          </div>
        </div>
        <Footer accessibility={this.state.accessibility} accessibilityToggle={this.toggleAccessibility} />

        <Transition in={this.state.popupStatus.isVisible} timeout={200}>
          {state => (
            <div className={"Popup " + state}>
              <Transition
                in={this.state.popupStatus.name === "MoreInfo"}
                timeout={200}
                mountOnEnter
                unmountOnExit
              >
                <MoreInfo
                  closePopup={this.closePopup}
                  data={this.state.popupStatus.data}
                />
              </Transition>

              <Transition
                in={this.state.popupStatus.name === "Instructions"}
                timeout={200}
                mountOnEnter
                unmountOnExit
              >
                <Instructions
                  data={this.state.popupStatus.data}
                  closePopup={this.closePopup}
                  positions={this.state.popupBase}
                  requireNewPosition={this.requireNewPosition}
                />
              </Transition>
            </div>
          )}
        </Transition>

        <Transition in={this.state.popupStatus.isVisible} timeout={200}>
          {state => <div className={"Overlay " + state} />}
        </Transition>
      </div>
    );
  }
}

export default withLocalize(App);
