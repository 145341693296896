import React from "react";
import { Translate } from "react-localize-redux";
import Tooltip from "rc-tooltip";

export class Circle extends React.Component {
  render() {
    let Animated = this.props.animated;
    let Color = this.props.color;
    return (
      <div className="CircleContainer">
        <div className={Animated ? "Circle Out" : "Circle"}>
          {/* Half cirlce. Top. Used flexbox for easier animating */}
          <div className="Top Flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 266.3 264.7"
              className={Color === 0 ? "Active" : null}
              onClick={() => this.props.changeColor(0)}
            >
              <path
                id="curve"
                className="cls-1"
                d="M0,264.7C4.4,120,121.4,3.5,266.3,0V162.5A110.8,110.8,0,0,0,162.6,264.7Z"
              />
              <text
                className="Curved"
                transform={Animated ? "translate(60 60)" : "translate(20 20)"}
                textAnchor="middle"
              >
                <textPath xlinkHref="#curve" startOffset="23%">
                  <Translate id="Strengths_03" />
                </textPath>
              </text>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 265.5 264.7"
              className={Color === 1 ? "Active" : null}
              onClick={() => this.props.changeColor(1)}
            >
              <path
                id="curve2"
                className="cls-2"
                d="M0,0C144.6,4,261.1,120.2,265.5,264.7H102.9A110.6,110.6,0,0,0,0,162.6Z"
              />
              <text
                className="Curved"
                transform={
                  Animated ? "translate(-60 60)" : "translate(-20 20)"
                }
                textAnchor="middle"
              >
                <textPath xlinkHref="#curve2" startOffset="23%">
                  <Translate id="Strengths_05" />
                </textPath>
              </text>
            </svg>
          </div>

          <span className="AbsoluteDescriptions">
            <div
              className="Description"
            >
              <Translate id="Strengths_04" />
            </div>
            <div
              className="Description"
            >
              <Translate id="Strengths_06" />
            </div>
            <div
              className="Description"
            >
              <Translate id="Strengths_08" />
            </div>
            <div
              className="Description"
            >
              <Translate id="Strengths_10" />
            </div>
          </span>
          {/* Middle text */}
          <div className="CircleInfo">
            <Translate id="ClickToChoose" />
          </div>

          {/* Half circle. Bottom */}
          <div className="Bottom Flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 266.3 267.1"
              className={Color === 2 ? "Active" : null}
              onClick={() => this.props.changeColor(2)}
            >
              <path
                id="curve3"
                className="cls-3"
                d="M493.4,610.8A110.9,110.9,0,0,1,389.6,506.2H227.1c3.1,145.9,120.6,263.6,266.3,267.1Z"
                transform="translate(-227.1 -506.2)"
              />

              <text
                className="Curved"
                transform={
                  Animated ? "translate(55 -55)" : "translate(15 -15)"
                }
                textAnchor="middle"
              >
                <textPath xlinkHref="#curve3" startOffset="59%">
                  <Translate id="Strengths_07" />
                </textPath>
              </text>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 265.5 267.1"
              className={Color === 3 ? "Active" : null}
              onClick={() => this.props.changeColor(3)}
            >
              <path
                id="curve4"
                className="cls-4"
                d="M610.8,506.2a110.6,110.6,0,0,1-103,104.5V773.3c145.4-4,262.4-121.5,265.5-267.1Z"
                transform="translate(-507.8 -506.2)"
              />

              <text
                className="Curved"
                transform={
                  Animated ? "translate(-55 -55)" : "translate(-15 -15)"
                }
                textAnchor="middle"
              >
                <textPath xlinkHref="#curve4" startOffset="59%">
                  <Translate id="Strengths_09" />
                </textPath>
              </text>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default Circle;
