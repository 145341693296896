import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Welcome from "../Welcome";
import Register from "../Register";
import Strengths from "../Strengths";
import Confirm from "../Confirm";
import Thanks from "../Thanks";
import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import Unsubscribe from "../Unsubscribe";
import UnsubscribeFailed from "../UnsubscribeFailed";
import NotFound from "../PageNotFound";
import ScrollToTop from "./ScrollToTop";

export class Router extends React.Component {
  render() {
    const isRegistered = this.props.isRegistered;
    return (
      <ScrollToTop>
        <Switch>
          <Route exact path="/" render={() => <Welcome />} />
          <Route exact path="/welcome" render={() => <Welcome />} />
          <Route
            exact
            path="/register"
            render={() => (
              <Register
                collectedData={this.props.collectedData}
                language={this.props.language}
              />
            )}
          />
          <Route
            exact
            path="/strengths"
            render={() =>
              isRegistered ? (
                <Strengths
                  selectItems={this.props.selectItems}
                  isRegistered={this.props.isRegistered}
                  changeItems={this.props.changeItems}
                  openPopup={this.props.openPopup}
                  fixedHeader={this.props.fixedHeader}
                  instructions={this.props.instructions}
                  selectedItems={this.props.selectedItems}
                  requireNewPosition={this.props.requireNewPosition}
                  newPosition={this.props.newPosition}
                />
              ) : (
                  <Redirect to="/register" />
                )
            }
          />
          <Route
            exact
            path="/confirm"
            render={() =>
              isRegistered ? (
                this.props.selectedItems.length < 3 ? (
                  <Redirect to="/strengths" />
                ) : (
                    <Confirm
                      selectedItems={this.props.selectedItems}
                      collectedData={this.props.collectedData}
                      changeItems={this.changeItems}
                    />
                  )
              ) : (
                  <Redirect to="/register" />
                )
            }
          />
          <Route
            exact
            path="/thanks"
            render={() =>
              isRegistered ? <Thanks /> : <Redirect to="/register" />
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            render={() => (
              <TermsAndConditions />
            )}
          />
          <Route
            exact
            path="/privacy-policy"
            render={() => (
              <PrivacyPolicy isRegistered={this.props.isRegistered} />
            )}
          />

          <Route exact path="/unsubscribe" render={() => <Unsubscribe />} />
          <Route
            exact
            path="/failed-to-unsubscribe"
            render={() => <UnsubscribeFailed />}
          />
          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    );
  }
}

export default Router;
