import React from "react";
import Select, { components } from "react-select";

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {props.selectProps.value !== null ? (
      <img
        src={require("../../images/languages/" +
          props.selectProps.value.code +
          ".png")}
        alt=""
      />
    ) : null}
    {children}
  </components.ValueContainer>
);

export const Option = props => (
  <components.Option {...props}>
    <img
      src={require("../../images/languages/" + props.data.code + ".png")}
      alt=""
    />
    {props.data.name}
  </components.Option>
);

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.Button = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.requireNewPosition !== nextProps.requireNewPosition) {
      this.buttonPosition();
    }
  }

  openInstructions = () => {
    document.body.classList.add("popupOpen");
    this.buttonPosition();
    this.props.openPopup("Instructions", "");
  };

  buttonPosition = () => {
    this.props.popupBase(this.Button.current.getBoundingClientRect());
  };

  render() {
    return (
      <header className="Header">

        <div className="Container">
          <div className="HeaderRow">
            <div className="MiddleBlock Logo"><img src={require("../../images/StrengthsLite_Logo.png")} alt="" /></div>
            <div className="LeftBlock">
              {this.props.instructions ? (
                <div
                  ref={this.Button}
                  className={
                    "BtnPlusInfo" +
                    (this.props.currentPopup === "Instructions" ? " Open" : "")
                  }
                  onClick={() => this.openInstructions()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 477.9 773.9"
                  >
                    <path
                      d="M646.4,163.9a194.5,194.5,0,0,0-50-66.5q-32.1-28.1-77.1-43.6T419.9,38.3c-34.3,0-65.8,5.7-94.7,17A229.2,229.2,0,0,0,249.3,104q-32.6,31.6-52.9,76.7c-2.1,4.6-4,9.4-5.9,14.2-16,41.8,14.9,86.6,59.6,86.6h6.5c27.8,0,52.7-17.7,61.1-44.2q27.3-85.8,103.2-85.8c15.8,0,30.2,2.5,43.3,7.7a97.8,97.8,0,0,1,33.6,21.5,100.1,100.1,0,0,1,22,32.3,102.7,102.7,0,0,1,7.9,40.8,136.5,136.5,0,0,1-1.7,22c-1.2,6.8-4.2,14.1-9.2,21.8s-12.3,16.6-22,26.7-23,22.2-39.8,36.3q-58.3,48.4-80.6,94-11.4,23.3-16.5,62.8c-1.2,8.6-2.1,17.8-2.9,27.6a63.7,63.7,0,0,0,63.6,68.8h0A63.9,63.9,0,0,0,482.2,555c1.8-22.9,5-39.1,9.4-48.5s10.9-18.1,21.8-29.4,25.2-24.5,43-39.3q61.8-51,84-94.5c15.9-28.6,23.8-60.5,23.8-95.4C664.2,217.6,658.2,189.6,646.4,163.9Z"
                      transform="translate(-186.2 -38.3)"
                    />
                    <path
                      d="M420.2,664.1h-2.1c-40.3,0-72.9,33.1-72.9,74h0c0,40.9,32.6,74,72.9,74h2.1c40.3,0,73-33.1,73-74h0C493.2,697.2,460.5,664.1,420.2,664.1Z"
                      transform="translate(-186.2 -38.3)"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
            <div className="RightBlock"></div>
          </div>

        </div>
      </header>
    );
  }
}

export default Header;
